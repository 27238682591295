.form-modal-title {
  border-bottom: 1px solid #efefef;
  padding: 0 6px 12px 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.file-upload-form .uploadDropzone {
  cursor: pointer;
  margin: 0 auto;
}

.file-upload-form .uploadDropzone .upload-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 25px;
}

.file-upload-form .uploadDropzone .upload-area:hover {
  opacity: 0.75;
}

.file-upload-form .uploadDropzone {
  width: 100%;
  height: 160px;
  border-width: 2px;
  border-color: rgb(170, 170, 170);
  border-style: dashed;
  border-radius: 5px;
}

.form-modal-content {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .form-modal-content {
    min-width: 350px;
  }
}

.form-logo {
  max-width: 200px;
}

.form-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
}

@media (min-width: 769px) {
  .form-logo-container {
    display: none;
  }
}

.form-container tr td {
  vertical-align: middle;
}
