.segment-occurrence-menu {
    min-width: 350px
}

.segment-occurrence-menu .filmstrip-item-time-display {
    font-size: 16px;
    padding: 4px 8px;
    line-height: unset;
}

.segment-occurrence-menu  .segment-text {
    margin-left: 10px;
    color: #666;
    white-space: normal;
}