.playlist-player {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

.playlist-player-responsive-container {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
}

.playlist-player-overlay-container {
  position: relative;
}

.playlist-player-overlay-container .content-speed-dial-menu {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 1s ease-in;
}

.playlist-player-overlay-container:hover .content-speed-dial-menu {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s ease-in;
}

.playlist-player-container .setting-buttons-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
}

.playlist-player-container {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 15px;
}

.playerOnly .playlist-player-container {
  padding-right: unset;
}

.playlist-player-container #player {
  width: 100%;
  padding-right: 10px;
  border-radius: 10px;
}

.playlist-player-container > .comments-section {
  display: block;
}

.playlist-player > .comments-section {
  display: none;
}

.playlist-player-info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chromeless .playlist-player-info-container,
.gallery-mode .playlist-player-info-container {
  margin: 0 10px;
}

.playlist-player-info-container .info-container-description {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
  padding-top: 12px;
}

.info-container-description a, .text-anchor {
  position: relative;
  text-decoration: none;
  color: #2196f3; /* Replace $primaryMain with your primary color */
  cursor: pointer;
}

.info-container-description a:hover, .text-anchor:hover {
  color: #1e88e5; /* Replace $primaryDark with your dark color */
  text-decoration: none;
}

.info-container-description a::after, .text-anchor::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px; /* Height of the underline */
  background-color: #1e88e5; /* Replace $primaryDark with your dark color */
  transform: scaleX(0); /* Start with no underline */
  transform-origin: center;
  transition: transform 0.2s ease-in;
}

.info-container-description a:hover::after, .text-anchor:hover::after {
  transform: scaleX(1); /* Show the underline on hover */
}

.playlist-player-info-container .info-container-lower {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.playlist-player-info-container .info-container-upper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.playlist-player-info-container .info-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.playlist-player-info-container .info-bar .icon-button {
  font-size: 18px;
}

.playlist-player-info-container .info-bar .glyphicon-share-alt,
.playlist-player-info-container .info-bar .like-button,
.playlist-player-info-container .info-bar .unlike-button {
  margin-left: 15px;
  margin-top: -7px;
}

@media (max-width: 991px) {
  .playlist-player-responsive-container {
    width: 100%;
    flex-direction: column;
  }
  .playlist-player-container {
    padding-right: 0;
  }
  .playlist-player-container > .comments-section {
    display: block;
  }
  .playlist-player > .comments-section {
    display: none;
  }
}

@media (max-width: 677px) {
  .playlist-player-container .open-editor-button {
    display: none;
  }
}
