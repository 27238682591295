.sidebar-nav {
  top: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Toggle Styles */

.page-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page-sidebar-header-container {
  background-color: #d1d2db;
  z-index: 10000;
  width: 100%;
}

.page-sidebar-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #a6a6b9;
  border-top-color: #eaebec;
  border-bottom-color: #eaebec;
  padding: 10px;
  width: 100%;
}

.page-sidebar-header h4 {
  margin-bottom: 0;
}

.page-sidebar-header-icon {
  background-color: #f6f6f6;
  width: 42px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-sidebar-header-icon:hover {
  background-color: #f0f0f0;
}

.page-sidebar-header-icon:hover > .icon-button {
  color: #793de6;
}

.page-sidebar-header-icon > .icon-button {
  font-size: 20px;
}
