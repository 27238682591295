.icon-button  {
  cursor: pointer;
  color: #5e35b1;
  display: flex;
  justify-content: center;
}

.icon-button:hover  {
  color: #7420ff;
}

.icon-button.disabled  {
  opacity: 0.25;
  cursor: default;
  color: #666;
}

.icon-button .badge {
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;  
  top: -15px;
  position: relative;
  left: -5px;
}