.mt-playlist .mt-playlist-item {
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid #d1d2db;
  margin-top: 5px;
  border-radius: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.mt-playlist .mt-playlist-item.isDragging {
  background-color: rgba(222, 204, 255, 0.65);
}

.mt-playlist .mt-playlist-item:hover {
  background: #f8f9ff;
}

.mt-playlist .mt-playlist-item .mt-playlist-body {
  padding: 2px 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mt-playlist .mt-playlist-item .mt-playlist-title {
  margin: 0 0 5px 0;
  display: flex;
  color: #5e35b1;
  font-size: 12pt;
  white-space: nowrap;
  height: 100%;
  align-items: center;
}

.mt-playlist .mt-playlist-item .mt-playlist-info,
.mt-playlist .mt-playlist-item .mt-playlist-author {
  color: #666;
  font-size: 8pt;
}

.mt-playlist .mt-playlist-item .mt-playlist-stats {
  display: flex;
  align-items: center;
}

.mt-playlist .mt-playlist-item .mt-playlist-author {
  font-weight: 600;
}

.mt-playlist-item.mt-playlist-item-selected,
.mt-playlist-item.mt-playlist-item-selected:hover {
  background-color: #e3f2fd;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.25);
}
.mt-playlist-item.mt-playlist-item-selected:not(.isDragAndDropEnabled):hover {
  cursor: default;
}

.mt-playlist-item .thumbnail-item-img {
  width: 150px;
  height: 85px;
  opacity: 0.85;
  transition: all 0.2s ease-in-out;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.mt-playlist-item:hover .thumbnail-item-img {
  opacity: 1;
}

.mt-playlist-item .mt-playlist-item-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.mt-playlist-item .playlist-icon {
  padding-left: 5px;
  color: #793de6;
}

.mt-playlist-item .icon-fire {
  color: #ff5628;
  padding-right: 5px;
}
