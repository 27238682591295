.tag-cloud {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.tag-cloud-tag {
    cursor: pointer;
    opacity: 0.85;
    transition: all 0.3s;
    padding: 10px;
}

.tag-cloud-tag:hover {
    opacity: 1 !important;
    transform: scale(1.2);
}
