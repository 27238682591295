form {
    width: 100%;
    margin: unset;
}

.form-container {
    display: flex;
    flex-direction: column;
}

.form-layout {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 0px;
}

.form-left {
    display: flex;
    flex-direction: column;
}

.form-right {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 100%;
}

.form-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.form-row  {
    margin: unset;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;    
}

.form-row .fa  {
  margin: 5px 10px;
}

.form-header-message    {
    width: 100%;
    text-align: center;
    color: #777; 
    margin-top: 10px;   
}

.form-thumbnail {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.form-thumbnail .thumbnail-item-time-display {
    top: unset;
    bottom: 3px;
}

.tooltip { z-index: 11000 !important; }

.tooltip-inner  {
  background-color: #888;
   z-index: 10000 !important;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #888/*rtl:ignore*/
}

.form-button-bar  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.form-button-bar-center  {
    justify-content: center;
}    

.form-button-bar-item   {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.form-button-bar-spacer {
    width: 10px;
    height: 10px;
}

.form-section-spacer {
    height: 25px;
}

.button-unset-line-height {
    line-height: unset !important;
}