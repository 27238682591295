.mt-playlist  {
  padding: 10px;
  width: 100%;
}

.mt-playlist .searchbar-container {
  margin-bottom: 20px;
}
.mt-playlist h4 {
  color: #697586;
  white-space: nowrap;
}

@media (max-width: 991px) {
  .mt-playlist {
    margin-top: 10px;
  }
}