.transcript-item {
    border: 1px solid #fff;
    border-radius: 0.25rem;
    transition: .15s ease-in-out;
    margin: 0 0 10px;
    padding: 5px;
}

.transcript-item.editable {
    cursor: text;
}

.transcript-item.editable:hover {
    border: 1px solid #ccc;
}

.transcript-item-text {
    color: #909090  ;
    padding-left: 10px;
}

.transcript-item.editable:hover .transcript-item-text {
    color: #793de6;
}
