.text-input-form-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

#text-input-form .form-group {
  margin: 0;
}

.textInputControl {
  border-color: #c0c0c0;
  box-shadow: none !important;
}

.textInputControl:focus {
  border-color: #b257e8;
}

.text-input-form-container .glyphicon.icon-button {
  padding: 0;
}

.text-input-form-container .error-style {
  color: #ef9a9a;
}

.text-input-form-container .text-input-status {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
