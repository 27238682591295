/* This fires as soon as the element enters the dorm */
.list-transition-enter,
.list-transition-appear {
    opacity: 0.01;
}
/* This is where we can add the transition*/
.list-transition-enter-active,
.list-transition-appear-active {
    opacity: 1;
    transition: opacity 800ms ease-in;
}

/* This fires as soon as the this.state.showList is false */
.list-transition-exit {
    opacity: 1;
}
/* fires as element leaves the DOM*/
.list-transition-exit-active {
    opacity: 0.01;
    transition: opacity 800ms ease-out;
}
