#quick-delete-dialog .form-layout {
    padding-top: 15px;
}

.quick-delete-popover .form-layout {
    padding: 0;
}

.quick-delete-popover {
    z-index: 11300;
}
