.chromeless .comment-list-panel,
.gallery-mode .comment-list-panel {
  margin: 0 10px;
}

.comment-list .comment-list-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mobile-comment-input {
  margin-top: 10px;
}

.excel-export-button {
  font-size: 15px;
  padding-left: 10px;
}