.px-loader-wrapper {
  width: 100%;
  height: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.px-cssload-loader {
  width: 81px;
  height: 81px;
}
.px-cssload-loader,
.px-cssload-loader:before,
.px-cssload-loader:after {
  animation: 1.15s infinite ease-in-out;
  -o-animation: 1.15s infinite ease-in-out;
  -ms-animation: 1.15s infinite ease-in-out;
  -webkit-animation: 1.15s infinite ease-in-out;
  -moz-animation: 1.15s infinite ease-in-out;
}
.px-cssload-loader:before,
.px-cssload-loader:after {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.px-cssload-loader:before,
.px-cssload-loader:after {
  content: "";
}

.px-cssload-loader {
  background-color: white;
  animation: px-cssload-fireworks 69s infinite ease-in-out;
  -o-animation: px-cssload-fireworks 69s infinite ease-in-out;
  -ms-animation: px-cssload-fireworks 69s infinite ease-in-out;
  -webkit-animation: px-cssload-fireworks 69s infinite ease-in-out;
  -moz-animation: px-cssload-fireworks 69s infinite ease-in-out;
}

.px-cssload-loader:before {
  content: "";
  width: 80%;
  height: 80%;
  background-color: white;
  top: 10%;
  left: 10%;
  box-shadow: 2px -1px 0 rgba(212, 204, 229, 0.7),
    2px 2px 0 rgba(54, 57, 163, 0.7), -1px 2px 0 rgba(121, 61, 230, 0.7),
    -2px -2px 0 rgba(244, 244, 244, 0.69);
  -o-box-shadow: 2px -1px 0 rgba(212, 204, 229, 0.7),
    2px 2px 0 rgba(54, 57, 163, 0.7), -1px 2px 0 rgba(121, 61, 230, 0.7),
    -2px -2px 0 rgba(244, 244, 244, 0.69);
  -ms-box-shadow: 2px -1px 0 rgba(212, 204, 229, 0.7),
    2px 2px 0 rgba(54, 57, 163, 0.7), -1px 2px 0 rgba(121, 61, 230, 0.7),
    -2px -2px 0 rgba(244, 244, 244, 0.69);
  -webkit-box-shadow: 2px -1px 0 rgba(212, 204, 229, 0.7),
    2px 2px 0 rgba(54, 57, 163, 0.7), -1px 2px 0 rgba(121, 61, 230, 0.7),
    -2px -2px 0 rgba(244, 244, 244, 0.69);
  -moz-box-shadow: 2px -1px 0 rgba(212, 204, 229, 0.7),
    2px 2px 0 rgba(54, 57, 163, 0.7), -1px 2px 0 rgba(121, 61, 230, 0.7),
    -2px -2px 0 rgba(244, 244, 244, 0.69);
}

@keyframes px-cssload-fireworks {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360000deg);
  }
}

@-o-keyframes px-cssload-fireworks {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360000deg);
  }
}

@-ms-keyframes px-cssload-fireworks {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360000deg);
  }
}

@-webkit-keyframes px-cssload-fireworks {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360000deg);
  }
}

@-moz-keyframes px-cssload-fireworks {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360000deg);
  }
}
