@media (min-width: 769px) {
  #edit-account-dialog .form-modal-content {
    min-width: 550px;
  }
}

.edit-account-form {
  margin: 20px 0;
}

#edit-account-dialog .direct-url {
  margin-top: 10px;
  align-items: center;
  width: 100%;
  display: table;
}

#edit-account-dialog .direct-url .direct-url-input {
  width: 100%;
  display: table-cell;
  color: #999;
  padding: 2px 10px;
}

#edit-account-dialog .direct-url .fa-clipboard {
  display: table-cell;
  padding-left: 10px;
}