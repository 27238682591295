.image-icon-button {
    width: 25px;
    height: 25px;
    background-size: 25px 25px;
    opacity: 0.685;
    cursor: pointer;
    position: absolute;
    z-index: 10000;
}

.image-icon-button:hover {
    opacity: 1.0;
}