#quick-share-dialog .form-layout {
    padding-top: 15px;
}

.quick-share-popover .form-layout {
    padding: 0;
}

.quick-share-popover {
    z-index: 11300;
}
