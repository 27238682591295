.chat-typing-bubble {
    background-color:#E6F8F1;
    padding:16px 28px;
    -webkit-border-radius: 20px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius: 20px;
    -moz-border-radius-bottomleft: 2px;
    border-radius: 20px;
    border-bottom-left-radius: 2px;
    display:inline-block;
  }
  .chat-typing-typing {
    align-items: center;
    display: flex;
    height: 17px;
  }
  .chat-typing-typing .chat-typing-dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: #6CAD96 ;
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
  }
  .chat-typing-typing .chat-typing-dot:nth-child(1) {
    animation-delay: 200ms;
  }
  .chat-typing-typing .chat-typing-dot:nth-child(2) {
    animation-delay: 300ms;
  }
  .chat-typing-typing .chat-typing-dot:nth-child(3) {
    animation-delay: 400ms;
  }
  .chat-typing-typing .chat-typing-dot:last-child {
    margin-right: 0;
  }
  
  @keyframes mercuryTypingAnimation {
    0% {
      transform: translateY(0px);
      background-color:#6CAD96; 
    }
    28% {
      transform: translateY(-7px);
      background-color:#9ECAB9; 
    }
    44% {
      transform: translateY(0px);
      background-color: #B5D9CB;
    }
  }