.filmstrip-item  {
  position: relative;
}

.filmstrip-item-time-display {
   padding: 0 2px;
   color: #fff;
   font-size: 10pt;
   background-color: #666;
   opacity: 0.65;
   line-height: 19px;
   border-radius: 2px;
}

.filmstrip-item .filmstrip-item-time-display {
  float: right;
}

.filmstrip-item .filmstrip-item-time-display {
   position: absolute;
   bottom: 3px;
   right: 3px;
}

.filmstrip-item-img {
  width: 89px;
  height: 50px;
  background-size: cover;
  background-position: center; 
  border-radius: 5px;
}
