@media (min-width: 769px) {
  #manage-share-users-dialog .form-modal-content {
    min-width: 550px;
  }
}

#manage-share-users-dialog #invite-others-button {
  margin-bottom: 10px;
}

#manage-share-users-dialog .quick-share-form.form-container .form-layout {
  padding: 0;
  margin: 0;
}

#manage-share-users-dialog .share-users-button-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#manage-share-users-dialog .share-users-button-bar label {
  margin-bottom: 0;
}

#manage-share-users-dialog .form-modal-body.modal-body .tabContent {
  padding: 0 15px;
}

.private-toggle {
  margin: 10px 0;
}

.table-row {
  cursor: pointer;
  transition: all 0.2s ease-in;
}

#manage-share-users-dialog table .share-link-col {
  width: 150px;
}

#manage-share-users-dialog table .action-col {
  width: 80px;
}

#manage-share-users-dialog table .share-status-col {
  width: 80px;
  text-align: center;
}

#manage-share-users-dialog table thead {
  background-color: #f0f0f0;
}

#manage-share-users-dialog table tbody tr td {
  vertical-align: middle;
}

#manage-share-users-dialog .table-action-button {
  width: 100px;
}
