.pin-input-group    {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    border: none;
}
.pin-input-group .pin-input {
    width: 50px;
    height: 50px;
    font-size: 30px;
    text-align: center; 
    margin: 0 10px;
    border-width: 1px;
}

.pin-input-group .pin-input:focus {
    border-color: #793de6;
    box-shadow: 0 0 0 0.2rem rgba(59,62,102,.25);
}

.pin-input-group .pin-input::-webkit-outer-spin-button,
.pin-input-group .pin-input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.pin-input-group .pin-input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}