.recording > *,
.recording > *:before,
.recording > *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// $bgColor: #b257e8;
$bgColor: #ff5628;

$numOfColBlocks: 3; // must be higher than 2
$deg: 360deg / $numOfColBlocks;

// $purple: #ff5628;
$purple: #b257e8;
$green: #59e857;
$blue: #0288d1;

$blocksColors: (
  1: $purple,
  2: $green,
  3: $blue,
);

$size: 140px;
$sizeBig: 500px;
$pad: 10px;
$numSize: 100px;
$totalAT: 5s;
$contBr: 20px;

$num1Len: 72.1554946899414;
$num2Len: 136.02162170410156;
$num3Len: 144.4256591796875;
$numJoin1-2: 82.63925170898438;
$numJoin2-3: 42.81303787231445;
$numJoin3-0: 40;

$totalLen: $num1Len + $num2Len + $num3Len + $numJoin1-2 + $numJoin2-3 +
  $numJoin3-0;

.recording {
  position: absolute;
  left: 50%;
  top: 50%;
  width: $sizeBig;
  height: $size;
  margin-top: $size / -2;
  padding: $pad;
  border-radius: 20px;
  transform: translateX(-50%);

  &__colored-blocks {
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 0;
    width: $sizeBig;
    height: 100%;
    margin-left: $sizeBig/-2;
    padding: $pad;
    border-radius: $contBr;
    perspective: 1000px;
    animation: recordingAnim $totalAT ease-in-out infinite,
      continuousAnimation $totalAT infinite;

    &-rotater {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      animation: rotationanimation 1.3s linear infinite;
    }

    &-inner {
      overflow: hidden;
      position: relative;
      height: 100%;
      background: $bgColor;
      border-radius: inherit;
    }
  }

  &__colored-block {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300%;
    height: 300%;
    transform-origin: 0 0;
    opacity: 0.65;

    @for $i from 1 through $numOfColBlocks {
      &:nth-child(#{$i}) {
        $rt: $deg * ($i - 1);
        $skew: 90deg - $deg;
        transform: rotate($rt) skewX($skew);
        background-color: map-get($blocksColors, $i);
      }
    }
  }

  &__inner {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 0;
    width: $sizeBig;
    height: 100%;
    margin-left: $sizeBig/-2;
    text-align: center;
    line-height: $size;
    font-size: 32px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 15px;
    transform: translateX(10px);
  }
}

@keyframes continuousAnimation {
  15%,
  100% {
    margin-left: $sizeBig/-2;
    width: $sizeBig;
  }
  25%,
  90% {
 
  }
}

@keyframes rotationanimation {
  to {
    transform: rotate(360deg);
  }
}

.recording .sk-wave {
  margin-right: 20px;
}