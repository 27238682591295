.page-sidebar-wrapper-right {
    height: 100%;
}

.page-sidebar-wrapper-right .page-sidebar {
    padding-bottom: 70px;
    height: 100%;
}

.page-sidebar-wrapper-right .page-sidebar .page-sidebar-menu {
    padding-top: 50px;
    box-shadow: 1px -1px 0 rgba(179,229,252,0.7),
										1px 1px 0 rgba(0,144,234,0.7),
										-1px 1px 0 rgba(3,168,244,0.7),
                                        -1px -1px 0 rgba(225,245,254,0.69);
    -webkit-box-shadow: 1px -1px 0 rgba(179,229,252,0.7),
										1px 1px 0 rgba(0,144,234,0.7),
										-1px 1px 0 rgba(3,168,244,0.7),
										-1px -1px 0 rgba(225,245,254,0.69);
    height: 100%;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}

.page-sidebar .badge {
    font-size: 10px;
    background-color: #b257e8;
 }
  