.camera-recorder-stage {
    flex: 1;
    /* Misc */
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Misc */
    height: 100%;
    width: 100%;
}
.camera-recorder-stage-resizer[data-direction='vertical'] {
    height: 2px;
    width: 100%;
    align-items: top;
    display: flex;
    justify-content: center;
}
.camera-recorder-stage-resizer-tooltip {
    margin-top: -15px;
}
.camera-recorder-stage-resizer .camera-recorder-stage-resizer-tooltip .btn-icon {
    cursor: row-resize !important;
}
.camera-recorder-stage__top {
    /* Initial height */
    height: 100%;
    width: 100%;
    /* Misc */
    align-items: center;
    display: flex;
    justify-content: center;
}
.camera-recorder-stage__bottom {
    /* Take the remaining height */
    flex: 1;
    /* Misc */
    align-items: top;
    display: flex;
    justify-content: center;
    min-height: 25px;
}