.file-upload-item {
  margin: 20px 0;
}

@media (min-width: 769px) {
  .file-upload-item {
    min-width: 550px;
  }
}

.upload-progress-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.upload-form-video {
  width: 200px;
  height: 113px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid #c2cad8;
}

.upload-form-thumbnail {
  object-fit: contain;
}

.progress {
  height: 25px;
  width: 100%;
  margin-bottom: 0px;
  margin-right: 5px;
}

.progress-bar {
  line-height: 25px;
  background-color: #793de6;
  color: #fff;
}

.upload-form-filename {
  font-size: 10px;
  margin-top: 5px;
}

.upload-form-channel {
  font-size: 14px;
}

.upload-form-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-form-status-msg {
  font-size: 14px;
  display: flex;
  align-items: center;
}

hr {
  border-top: 1px dashed #ccc;
}

.upload-form-video.active-video {
  opacity: 0.85;
  cursor: pointer;
}

.upload-form-video.active-video:hover {
  opacity: 1;
}

.file-upload-button-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  width: 200px;
}

@media (max-width: 768px) {
  .form-layout {
    flex-wrap: wrap;
  }

  .file-upload-item .form-left {
    margin: 0 auto;
    padding-bottom: 15px;
    align-items: center;
  }

  .file-upload-item .form-right {
    margin: 0 15px;
  }
}
