.page-breadcrumb {
    background: none;
    margin: 0;
    color: #b0bec5;
    padding: 0.5rem 1rem;
}

.page-breadcrumb>li+li:before {
    display: none;
}

.page-breadcrumb .breadcrumb-item .active {
    color: #5e35b1;
    font-weight: 500;
    padding-left: 8px;
    padding-right: 8px;
}

.breadcrumb-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%
}

