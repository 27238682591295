
.channel-page .page-head {
  margin-bottom: 20px;
}

.channel-header-button-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.channel-page .channel-owner-avatar {
  margin: 15px 20px 20px 20px;
}

.channel-page .info-container-area {
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding: 0;
}

.channel-page .info-container-upper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
}

.channel-page .info-container-lower {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.channel-page .setting-buttons-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.channel-page .info-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
