#quick-embed-dialog .form-layout {
    padding-top: 15px;
}

.quick-embed-popover .form-layout {
    padding: 0;
}

.quick-embed-popover {
    z-index: 11300;
}
