.clipboard-copy-field {
  width: 100%;
  display: flex;
  align-items: center;
}

.clipboard-copy-field .clipboard-copy-field-input {
  width: 100%;
  font-size: 10pt;
  padding: 5px;
  border: 1px solid #dadada;
  border-radius: 5px;
}

.clipboard-copy-field .icon-button {
  padding-left: 10px;
}
